import React from 'react';
import styled from '@emotion/styled';

const Svg = styled('svg')`
  width: 450px;
  max-width: 100%;
  fill: #b5b5b5;
`;
export default () => (
  <Svg
    version="1.1"
    id="svg"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 539.6"
  >
    <g id="svgg">
      <path
        id="path0"
        className="st0"
        d="M172.7,45.6c-0.1,0-2,0.2-4.3,0.3c-3.8,0.2-4.9,0.3-9.9,1.4c-5.7,1.2-9.5,1.9-14.4,2.7
		c-1.5,0.2-3.3,0.7-4.1,1c-1.6,0.7-4.3,1.3-6.8,1.7c-0.9,0.1-2.5,0.6-3.6,1s-2.8,1-3.9,1.2c-2.5,0.6-3.1,0.8-6.3,2.3
		c-2,0.9-3.1,1.3-4.9,1.6c-7.6,1.3-7.7,1.3-10.2,2.6c-3.1,1.5-4,1.8-6.8,2.4c-4.5,0.9-4.7,1.5-0.8,2.4c1.1,0.2,1.8,0.5,2.3,1
		s1.1,0.7,1.9,0.8c2.2,0.3,2.1,0.9-0.2,2.1c-0.8,0.4-2.6,1.4-4,2.2c-2.5,1.4-2.7,1.5-4.5,1.6C90.6,74,90,74.2,88.5,75
		c-1,0.5-2.8,1.2-3.9,1.6c-7.4,2.5-13.4,7.3-16.8,13.6c-1.6,2.9-1.6,3-5.6,7c-1.9,1.9-3.4,3.7-3.5,3.9c-0.1,0.3-0.2,1.2-0.2,2
		s-0.3,2.1-0.6,2.8c-0.4,1.2-0.4,1.5-0.2,2.5c0.3,1.5,0.1,2.4-1,4.2c-2.3,3.8-2.3,4.4-0.2,4.4c1.6,0,1.9,0.3,1.5,1.7
		c-0.4,1.6-1,2.8-2,4.1c-0.5,0.7-1.1,1.8-1.3,2.4c-0.6,1.9-1.3,3-5.8,9.3c-2.2,3.1-2.9,4.6-3.2,7.3c-0.3,2.8-0.9,4.3-2.5,6.6
		c-2.9,4.2-3.2,6-1.3,7.6l1,0.9l-0.1,2.2c-0.2,2.4,0.2,5,1,7.4c0.3,0.8,0.4,1.9,0.5,2.9c0,0.9,0.2,2.2,0.4,2.9c0.3,1,0.4,1.8,0.3,3
		c-0.2,2.7,0.3,3.4,1.8,2.4c1.6-1,2.4-0.9,3,0.5c0.2,0.6,0.6,1.4,0.9,1.8c1,1.4,1.2,2.2,1.4,5.8c0.2,3.3,0.2,3.5,1,5
		c1.4,2.8,1.9,4.4,2.2,7.2c0.1,1.5,0.4,3.8,0.6,5.1c0.2,1.4,0.4,3.5,0.5,4.7s0.4,4.3,0.8,6.9c1.2,8.4,1.3,10.6,1.4,19.1
		c0,9.9-0.3,13.9-1.4,14.5c-1.5,0.9-1.8,6.4-0.5,8.9c0.7,1.4,1,3,0.6,3.6c-0.3,0.4-1.2,0.1-3.2-1.1c-3.1-1.9-4.7-2-5.5-0.3
		c-0.4,0.8,0.2,11.2,0.9,16.6c0.2,1.8,0.5,4.5,0.6,6c0.3,5.2,0.9,6.1,2.7,3.6c0.5-0.8,1.2-1.7,1.5-2l0.5-0.6l0.5,0.6
		c0.5,0.6,0.5,0.6-0.1,5.9c-0.3,2.3-0.4,4.9-0.4,10.3c0,8.5,0,8.5,2.4,12.9s2.8,4.8,5.2,5.3c1.6,0.4,2,0.7,2.5,2.3
		c0.3,0.9,0.7,1.7,1.5,2.5c0.8,0.9,1.6,2.3,3.3,5.7c2.7,5.5,2.7,5.5,6.8,6.8c3.4,1.1,4,1.6,6,5.6c1.2,2.3,1.3,2.5,2.7,2
		c1.2-0.4,2.9-0.3,3.4,0.2c0.1,0.1,0.3,1,0.4,2c0.3,2.4,1.4,3.7,3.2,3.7c0.3,0,0.6,0.2,0.7,0.3c0.1,0.2,0.2,0.3,0.3,0.2
		c0.1-0.1,0.2,0.1,0.3,0.3c0.1,0.4,1.5,1.3,1.5,0.9c0-0.1,0.2-0.2,0.4-0.2s0.6-0.2,0.8-0.4c0.8-0.8,0.2-3.2-0.8-3.2
		c-0.7,0-0.5-0.6,0.4-1.5c1.2-1.2,1.3-1.6,0.7-3.2c-0.3-1-0.5-1.9-0.5-3.8c0-3.4-0.3-5.5-1-6.8c-1-2.1-1.4-3.8-1.3-6.6
		c0-1.4-0.1-3-0.2-3.4c-0.3-1.1,0.1-1.6,1.4-1.6c1.2,0,1.7-0.8,0.9-1.6c-1.7-1.5-3.5-4.1-3.7-5.3c-0.2-1.3-1.3-4-1.5-4
		c-0.5,0-1.5-1.3-1.3-1.5s0.2-0.3,0-0.6c-0.1-0.2-0.2-0.4-0.1-0.4c0.1-0.1-0.3-0.6-0.9-1.2s-1-1.2-1-1.4c0-0.7-2.5-0.9-3.3-0.3
		c-0.6,0.5-1.7-0.1-2.4-1.3c-0.7-1.2-0.9-3-0.4-3.3s-0.1-3.3-0.7-4c-0.1-0.1-0.2-0.4-0.2-0.7s-0.1-0.6-0.2-0.6
		c-0.1-0.1-0.3-0.6-0.4-1.3c-0.1-0.6-0.2-1.1-0.4-1.1s-0.2-0.1-0.2-0.2c0-0.3-1-1.4-1.3-1.4c-0.2,0-0.8-2.5-1.2-5.2
		c-0.1-0.7-0.2-3-0.3-5.2c-0.1-3.3-0.2-4.2-0.6-5.3c-0.8-2.5-0.6-4.2,1.4-12.3c0.2-0.7,0.3-1.6,0.3-2.1c0-1.9,0.7-5.1,1.4-6.6
		c0.5-1,0.8-2,0.9-3c0.2-1.8,0.5-2.8,1.2-3.7c0.3-0.4,0.6-1.1,0.6-1.5c0.1-0.5,0.3-1.3,0.6-1.9c0.3-0.5,0.6-1.5,0.7-2.2
		c0.3-2.2,0.5-2.5,1.8-2.3s1.9,0.8,1.9,1.8c0,0.4,0.2,1.1,0.5,1.5c0.6,1.1,0.6,2.8,0,3.3c-0.2,0.2-0.4,0.5-0.4,0.7
		c0,0.5-0.9,0.9-1.3,0.6c-0.4-0.3-1.3,0-1.5,0.4c-0.1,0.2,0,0.4,0.1,0.5c0.1,0,0.2,0.3,0.2,0.6s0.2,0.7,0.4,0.8
		c0.5,0.4,1.2,1.1,1.8,1.8c0.2,0.3,0.7,0.7,1.1,0.9c0.3,0.2,0.6,0.5,0.6,0.7s1.3,1.1,3.5,2.6c1.1,0.7,4.1,1.4,6.4,1.5
		c2,0.1,2.9,0.4,4.6,1.5c0.5,0.3,1.6,1,2.5,1.4c1.2,0.6,2.1,1.4,3.4,2.7c1.9,2,3.5,2.9,5.4,3.2c1.8,0.2,2.5,0.5,3.3,1.4
		c1.6,1.7,2.8,1.9,4.1,0.6c1-1,1-1.4,0.2-3.4c-1.3-2.9-0.9-3.6,1.9-3.6c2.7,0,3-0.3,3.5-3.7c0.3-1.7,1.3-3.1,2.5-3.4
		c0.9-0.2,1.2,0.1,1.9,2.6c2,6.4,7.4,9.9,12.4,8c4.5-1.6,7.4-5.4,7.8-9.9c0.2-2.6,0.6-3.3,1.8-3.3c1.3,0,1.6-0.3,1.2-1
		c-0.8-1.4-5.9-3.5-8.8-3.5c-1.1,0-2-0.2-3.1-0.6c-1.1-0.4-1.9-0.6-2.8-0.5c-0.7,0-1.9,0-2.6,0c-0.9,0-1.8,0.1-2.5,0.4
		c-0.6,0.2-2,0.6-3,0.7c-1,0.1-2.8,0.5-3.9,0.8s-2.7,0.6-3.6,0.7s-2.2,0.3-2.9,0.5c-0.8,0.2-2,0.4-2.8,0.5c-1.5,0.2-3.2,0.6-4.5,1.2
		c-2.4,1.1-3.6,0.8-4.4-0.9c-0.3-0.7-0.8-1.4-1.3-1.7c-1.7-1.2-1.6-1.9,0.3-2.2l1.3-0.2v-1.6c0-2.7,1.2-4.5,3.5-5.3
		c1.2-0.4,1.3-0.5,1.5-1.2c0.2-0.9,0.9-1.6,1.8-1.6c1.5,0,3-0.5,3.8-1.4c1-1.1,2.9-1.2,4.7-0.4c0.6,0.2,2,0.6,3.1,0.7
		c1.1,0.1,2.1,0.3,2.3,0.4c0.7,0.4,3.9,1.1,8.5,1.8c2.9,0.4,6.3,1,7.6,1.2c1.3,0.3,3.1,0.6,4.1,0.8c2.9,0.6,11.1,3.8,13.2,5.2
		c1.2,0.8,4.4,4.9,5.1,6.6c0.6,1.5,2.3,4.5,3,5.6c0.2,0.3,0.9,1.5,1.4,2.6c0.6,1.1,1.5,2.6,2.1,3.2s1.3,1.4,1.5,1.7
		c0.8,0.9,1.6,0.9,2.6-0.2c1.5-1.6,1.6-2.1,2.3-7.1l0.3-2.2l-0.8-2.3c-0.4-1.3-1.1-3.1-1.4-4.1c-0.3-1-0.8-2.2-0.9-2.7
		c-0.8-2.3-6.2-8.5-7.8-9.1c-0.5-0.2-1.4-0.8-2.1-1.5c-0.7-0.7-1.5-1.3-1.9-1.3c-0.4-0.1-1.4-0.5-2.2-0.9s-2.3-1-3.3-1.2
		s-2.3-0.8-2.9-1.2c-2.1-1.4-2.9-1.7-4.2-1.7c-0.8,0-1.8-0.2-2.8-0.6c-1.3-0.5-1.8-0.6-3.8-0.6c-1.9,0-2.6-0.1-4.1-0.5
		c-1.5-0.5-2.2-0.6-4.9-0.6c-3.4,0-3.6,0-6.8,1.6c-2.8,1.4-4.4,1.8-5.4,1.7c-0.5-0.1-0.9,0-1,0.1s-0.4,0.2-0.6,0.2s-0.4,0.1-0.4,0.2
		c0,0.5-1,0.2-1.5-0.5c-0.4-0.6-0.6-0.7-1.3-0.7c-2.2,0.2-8.5,0.1-10.6-0.1c-3.7-0.4-4.9-0.2-6.6,0.9c-3.4,2.1-6.3,1.9-6-0.4
		c0.2-1.3,0-1.9-0.8-2.8c-1.3-1.4-1.6-2.4-1.4-4.5c0.1-1.1,0.1-2,0-2.1s-0.2-0.4-0.2-0.8c0-0.4-0.1-0.7-0.2-0.8s-0.5-0.9-0.8-1.8
		c-0.3-0.9-0.7-1.7-0.8-1.8c-0.1-0.1-0.1-0.5-0.1-0.8c0.1-0.4,0-0.8-0.3-1.1s-0.5-0.6-0.5-0.7c0-0.1-0.2-0.2-0.6-0.1
		c-0.4,0.1-0.6,0-0.6-0.1s-0.2-0.4-0.3-0.6c-0.5-0.5-0.1-2.8,0.5-2.8c0.1,0,0.3-0.3,0.5-0.7c0.7-1.5-0.1-5.5-1.3-7.5
		c-0.5-0.8-0.6-1.8-0.2-3.1c0.2-0.7,0.2-1-0.2-2c-0.3-0.7-0.5-1.6-0.5-2.3s-0.1-1.8-0.3-2.4c-0.2-0.7-0.4-1.8-0.5-2.5
		c-0.2-1.9-0.8-3.3-1.5-4.1c-0.4-0.4-0.9-1.1-1.2-1.5s-1-1.3-1.6-1.9c-1.1-1.1-1.7-2.2-1.7-3c0-0.2-0.2-0.7-0.5-1
		c-0.8-0.8-0.9-1.7-0.4-2.9c0.4-1,0.4-1.2,0-4.2c-0.9-6.6-0.5-8.6,2.1-11.2c1.9-1.9,2.2-1.8,3.6,1c1.2,2.4,1.4,2.6,2.7,1.9
		c1.1-0.6,3.8-3.5,5.9-6.4c0.9-1.2,1.7-1.9,4.5-3.3c1.3-0.7,3-1.8,3.8-2.4c3.6-3,4.2-3.4,5.2-3.9c0.6-0.3,1.7-0.8,2.4-1
		c1.4-0.5,7.1-3.5,9.7-5.1c2.2-1.4,5.4-3,5.8-3s2.1-0.6,5.6-1.8c1.4-0.5,3.1-1,3.7-1c0.6-0.1,1.7-0.4,2.3-0.8
		c1.2-0.6,1.5-0.7,6.8-1.4c1.4-0.2,2.9-0.5,3.5-0.7c0.5-0.2,1.8-0.4,3-0.6c1.1-0.1,2.8-0.5,3.7-0.8s2.4-0.6,3.4-0.7s2.2-0.3,2.7-0.4
		c1.2-0.4,4.1-0.5,6-0.3c1.1,0.2,1.6,0.1,2.7-0.3c2.2-0.8,2.2-0.8,3.5,0.2c1.5,1.2,2.3,1.3,3.6,0.8c0.8-0.4,1.1-0.4,1.9-0.2
		c2.4,0.5,4.7-0.1,5.7-1.5c0.8-1.2,1.4-1.3,2.6-0.5c1.2,0.8,1.5,0.6,3.1-1.6c0.7-0.9,1.5-1.8,1.9-2c0.9-0.5,3.4-0.2,3.9,0.4
		c0.6,0.7,1,0.5,2.5-0.9c3.3-3.4,5.5-4,5.5-1.4c0,1.4,0.9,1,3.9-1.6c2.9-2.5,3.5-2.8,4.1-2.8c0.9,0,0.6,0.8-0.5,1.7
		c-0.6,0.4-1.4,1.3-1.7,1.9c-0.4,0.6-0.9,1.4-1.3,1.8c-0.8,0.9-0.7,1.4,0.2,2.6c0.3,0.4,0.6,1.1,0.6,1.5c0.1,0.7,0.1,0.8,1.1,0.9
		c0.6,0.1,1.5,0.3,2,0.5c1.3,0.5,2.8,0.2,5.7-1.3c3.1-1.6,7.3-3.5,9.2-4.2c0.9-0.3,2.2-0.9,2.8-1.2c0.6-0.4,2.1-1.2,3.4-1.8
		c1.2-0.6,2.6-1.4,3-1.7c0.4-0.3,1.1-0.7,1.4-0.9c0.8-0.4,2.4-2,2.4-2.4c0-0.7,0.9-1.4,2-1.5c0.6-0.1,1.7-0.3,2.4-0.6
		c1.4-0.5,1.4-0.5,2.5,0.6c0.5,0.5,0.7,0.5,2.7,0.5c1.8,0,2.7,0.1,4.6,0.5c2.9,0.7,5.4,1.2,7,1.4c2,0.2,3.6,1.4,4.5,3.1
		c0.6,1.1,2.9,3,5.2,4.2c2.3,1.3,3.6,2.3,5,4.5c0.6,0.9,1.7,2.3,2.4,3.1c0.7,0.7,1.5,1.8,1.8,2.3c0.9,1.6,4,5.4,5.5,6.9
		c0.8,0.8,1.9,2.1,2.5,3c1.4,2.1,4,5,5.5,6.2c1,0.8,1.2,1.1,1.5,2.2c0.2,0.7,0.6,1.6,0.9,2.1c1.1,1.7,1.5,2.9,1.4,4.4
		c-0.2,2.4-0.1,3.5,0.5,5.5c0.6,1.7,0.7,2.3,0.5,3.3c-0.1,1.4,0.4,4,1.3,6.3c0.5,1.3,0.5,1.3-0.1,5.4c-0.3,2.2-0.3,2.4,0.1,4.1
		c0.3,1,0.5,1.9,0.5,1.9s-0.5,0.7-1.1,1.4c-1.1,1.3-1.6,2.3-1.6,3.7c0,0.4-0.2,1.2-0.5,1.7c-1.8,3.4-1.8,3.1-1.8,5.8
		c0,1.4,0.1,3.1,0.3,3.9c0.2,0.8,0.4,2.3,0.5,3.4c0.1,1.3,0.5,2.7,0.9,3.8c1.7,4.5,3.9,12.4,4.9,17.8c0.2,1.1,0.6,2.8,0.9,3.8
		s0.8,3.3,1,5.2c0.3,2.5,0.8,4.6,1.6,7.6c1.2,4.2,1.6,6,1.8,9c0.1,0.9,0.3,2.1,0.6,2.7c0.3,0.7,0.5,2.1,0.6,3.9
		c0.1,1.5,0.4,3.4,0.5,4.1c0.2,0.7,0.3,2.2,0.3,3.4c0,1.5,0.2,3,0.6,4.7c0.9,3.8,0.9,3.9,1.9,4.2c0.5,0.1,1.5,0.5,2.3,0.7
		c1.8,0.6,2.5,0.5,3-0.6c0.9-1.9,0.2-3.4-2.1-4.2c-2.2-0.8-2.5-1.8-1.4-5l0.6-1.8l-0.3-5.3c-0.2-3-0.4-5.9-0.6-6.7
		c-0.4-1.7-0.3-5.3,0.1-6.4c0.1-0.4,0.6-2,1-3.7c0.6-2.6,0.9-3.3,2-5.1c1.6-2.7,1.9-4.3,1.5-8.1c-0.3-2.6-0.2-3.3,1.5-13.2
		c0.3-1.9,0.3-2.2-0.1-4.5c-0.2-1.3-0.5-2.9-0.6-3.5s-0.5-2.3-0.9-3.7c-1.9-6.5-1.6-10.4,1.4-14.2c1.1-1.4,1.1-1.5,1.1-3
		c0-1.3,0.1-1.6,0.6-2.4c0.8-1.2,0.7-1.5-0.9-4.2c-1.9-3.2-2.2-3.8-2.4-5.7c-0.4-3.6,0.5-4.4,3.9-2.9c2.4,1,2.9,0.9,2.9-0.8
		c0-1.3-0.8-2.4-3.1-4c-2.4-1.8-2.6-2.1-2.8-4.4c-0.1-1.1-0.3-2.6-0.5-3.4c-0.5-2.3-0.5-4.3,0.2-5.9c0.9-2.2,0.6-4-1.2-7.6
		c-1.1-2.2-1.3-3.2-1-5.1c0.2-1,0.2-2,0.1-2.7s-0.1-1.6,0-2.1c0.2-1.2,0-1.7-1.3-3.2c-1-1.1-2.4-4.1-2.4-5.1c0-0.6-1.6-2-4.2-3.6
		s-2.6-1.6-1.5-2.2c1-0.6,1-1.2,0.1-2.9c-0.4-0.7-0.8-1.7-0.9-2.3c-0.5-1.7-5-5.7-8.9-7.9c-1.1-0.6-2.4-1.4-2.9-1.7
		c-0.5-0.3-1.6-0.7-2.4-0.9c-0.8-0.2-2.3-0.6-3.3-1s-2.9-0.8-4.2-1c-1.3-0.2-3.1-0.7-4.1-1c-1-0.4-2.5-0.8-3.3-1
		c-2.1-0.5-2.6-0.9-2.9-2.5c-0.5-2.5-1.1-3.5-2.9-4.5c-1.3-0.8-2.5-2.5-2.5-3.5c0-0.4-0.4-1.4-0.8-2.3s-0.9-2.4-1.1-3.2
		c-0.6-2.7-1.1-3.1-2.3-1.9c-1.3,1.3-3.2,0.6-5.1-1.9c-1.9-2.6-4.6-5.1-6.4-5.8c-0.8-0.3-2.7-2.4-3.7-4c-0.8-1.2-4.4-3.9-7.2-5.3
		c-1.2-0.6-2.6-1.4-3.3-1.8c-2.7-1.6-7.6-4-9.8-4.8c-1.3-0.5-2.7-1-3.1-1.2c-2.8-1.4-7.5-2.9-9.2-2.9c-0.6,0-1.8-0.2-2.8-0.3
		c-2-0.4-10.9-0.5-15.4-0.2c-3.2,0.2-3.8,0-7.6-2.5c-2-1.3-4.1-2.2-7.7-3.1C183.8,45.6,173,45.3,172.7,45.6 M79.9,67.1
		c-2.9,0.6-9.4,3.2-10.7,4.2C66.7,73.2,68.9,73,72,71c1-0.7,2.3-1.2,4.2-1.8C82.5,67.3,84.7,66,79.9,67.1 M229.5,223.3
		c-2.3,0.8-2.8,1.1-3.9,2.3c-1.2,1.4-2.3,2-3.8,2.2c-1.1,0.1-1.3,0.3-2.8,1.7c-1.1,1-2,1.6-2.6,1.8c-1.4,0.4-1.5,0.6-1.5,1.9
		c0,0.8-0.2,1.6-0.7,2.5c-1.1,2.2,0.1,2.6,4.2,1.4c4.7-1.4,6-1.8,7.6-2.4c1-0.4,2.5-0.8,3.5-1c14.2-2.6,19.2-4.3,18.7-6.2
		c-0.5-2.1-2.2-2.9-7.7-3.6c-2-0.3-4.2-0.6-4.9-0.8C233.4,222.6,231.4,222.6,229.5,223.3 M239.3,241.6c-0.2,0.1-1.1,0.3-2,0.5
		c-2,0.3-3.5,0.8-4.8,1.5c-0.5,0.3-1.6,0.7-2.4,0.9c-1.1,0.3-1.7,0.6-2.5,1.4c-0.6,0.6-1.6,1.5-2.2,2c-1.3,1.1-3.5,4.3-3.2,4.6
		c0.5,0.5,3-0.8,5.3-2.6c3.1-2.4,3.9-2.4,4.7,0.2c1.9,5.9,4.6,8.8,8.3,8.9c7.4,0.1,12.1-3.5,12.7-9.8c0.2-2.5,0.4-2.6,2.8-1.6
		c0.9,0.4,1.7,0.9,2,1.3c2.2,2.7,3.2,3.5,4.7,3.6c0.8,0.1,1.8,0.3,2.3,0.5c3,1.2,3.1-1.8,0.1-3.8c-5.3-3.6-5.9-3.8-10.1-5.1
		c-1.1-0.3-2.7-0.9-3.6-1.2c-2-0.7-4.5-1.1-7.3-1.2c-1.2,0-2.7-0.1-3.4-0.2C240.2,241.4,239.6,241.5,239.3,241.6 M267,244.8
		c0,0.3,1.2,1.2,1.4,1c0.3-0.2-0.7-1.2-1.1-1.2C267.2,244.5,267,244.6,267,244.8 M245.6,246.3c1.5,1.2,1.1,3.2-0.7,3.2
		c-1,0-2.1-1-2.1-1.9C242.7,246.3,244.5,245.4,245.6,246.3 M145.8,251.3c1.3,1.3,0.6,2.9-1.1,2.9c-1.2,0-2.1-0.8-2.1-1.8
		C142.6,250.8,144.6,250.1,145.8,251.3 M158.1,253c0.2,0.2,0.9,1,1.6,1.8c0.7,0.7,1.7,2,2.1,2.7c1.6,2.5,2.1,3,2.8,3
		c1.4,0,0.7-2.5-1.2-4.5C162,254.5,157.2,251.8,158.1,253 M171.5,297.9c-0.9,1-1.4,2-2.2,4c-0.4,1.2-1.1,2.7-1.5,3.5
		c-1.1,2-1.3,4.1-0.3,3.4c2.4-1.7,6.9-5.9,7-6.6c0.4-1.6-0.5-4-1.7-4.6C172.1,297.3,172,297.3,171.5,297.9 M163.6,315.2
		c-3.1,3.1-5.2,11.2-3.7,14.1c0.7,1.4,6.4,5.7,7.5,5.7s3,4.5,2.9,6.7l-0.1,1.5l1,0.1c0.9,0.1,1.2-0.1,2.2-0.7c1.4-1,1.4-1,2.6-0.2
		c1.7,1,3.4,0.4,4.1-1.5c0.4-1,0.8-1.3,2.8-1.7c0.4-0.1,1.2-0.7,2.1-1.6c1.5-1.5,3-2.3,4.7-2.3c1.4,0,4.1-1.9,5.7-3.9
		c2.8-3.7,3.9-4.6,6.8-5.3c7.1-1.8,13.7-0.7,11.9,2c-1,1.4-0.8,1.8,1.1,1.8c1.3,0,1.8-0.4,2.7-2.4l0.8-1.7l-0.3-1.5
		c-1.2-6.4-6.7-7.4-13.8-2.5c-1.3,0.9-4.7,2.4-5.7,2.6c-0.5,0.1-1.3,0.2-1.9,0.4c-1.1,0.3-4.7-0.1-6.3-0.5c-2-0.6-4.6-2.5-7.6-5.6
		c-3.7-3.7-4.3-4.2-5.5-4.2c-1.5,0-5.2,1.3-7.3,2.5c-2.8,1.7-4.4,1.1-5.1-1.6C165,314.1,164.7,314,163.6,315.2 M93.9,346.7
		c0.1,0.2-0.4,0.9-0.7,0.9c-0.1,0-0.1-0.3-0.1-0.6C93.1,346.5,93.6,346.2,93.9,346.7 M107,353.3c-0.3,0.9,0.5,2.2,1.3,2.1
		c0.6-0.1,0.8-1.5,0.3-1.9C108,353,107.1,352.9,107,353.3 M174.6,363.7c-0.2,0.1-0.7,0.5-1.2,0.9c-0.7,0.7-1,0.8-2,0.8
		c-0.6,0-1.6,0.1-2,0.2c-0.6,0.2-1.1,0.2-2.1-0.1c-0.7-0.2-1.5-0.3-1.9-0.2c-0.3,0.1-1.4,0.2-2.3,0.4c-0.9,0.1-2.5,0.3-3.4,0.4
		c-4.6,0.6-8.8,4.5-6.8,6.4c1.7,1.6,8.4,1.2,11.4-0.7c0.7-0.4,1.5-0.8,1.8-0.9s1.3-0.4,2.4-0.7c1.8-0.6,1.9-0.6,3.5-0.3
		c3.4,0.6,10.2,0.8,13,0.4c2.2-0.3,3.1-0.3,5-0.1c5.1,0.5,10.9-0.2,10.9-1.4c0-0.4-1.2-1.5-2-1.6c-1.2-0.2-2.8-0.8-4-1.3
		c-1.5-0.7-1.7-0.7-2.7,0.1c-0.5,0.4-1.1,0.7-1.5,0.7s-1.1,0.2-1.7,0.5l-1,0.4l-1-0.6c-0.5-0.3-1-0.7-1.1-0.8
		c-0.1-0.1-0.6-0.2-1.3-0.1c-1,0.1-1.6,0-4.2-0.9c-1.7-0.6-3.4-1.1-3.9-1.2s-1.1-0.2-1.2-0.2C175.1,363.6,174.8,363.7,174.6,363.7
		 M223.6,364.6c-0.2,0.1-0.7,0.4-1.2,0.6c-1.3,0.8-3.2,1.1-4.6,0.7c-1.8-0.6-5.7-0.3-7.6,0.6c-2.6,1.2-2,1.6,2,1.3
		c5.8-0.5,6.2-0.5,7.7-0.1c0.8,0.2,2.7,0.6,4.2,0.8c1.7,0.3,3.2,0.7,4.1,1c2.7,1.2,8,0.5,7.4-1c-0.2-0.5-0.8-1-1.9-1.4
		c-0.4-0.2-1.4-0.7-2.1-1.1C229.8,365,225,364.1,223.6,364.6 M21.2,503.9c-12,5-5,7.4-1.7,11.3c1.8,2.1,10.6,8,12.3,11l11.4,7.7
		c0.8,0.6,1.7,1,2.6,1.4l5.1,1.8c3.9,1.3,7.9,2,12,2c29.2,0.1,53,0.9,53,0.8c0-0.4-1.3-2.1-2.7-3.7c-5.6-6-7.5-10.2-5.5-12.3
		c0.4-0.4,0.7-1.5,1.1-4c0.1-0.4,0.5-1.3,0.9-1.8c0.8-1.1,1.2-2.4,1.2-4.2c0-1.5,0.3-2,1.4-2.4l0.9-0.4l-0.1-1.1
		c-0.1-0.7-0.4-1.6-1-2.6c-1.7-2.8-2.1-4.7-1.2-5.7c0.3-0.3,0.3-0.6,0.2-1.5c-0.1-0.6-0.2-1.8-0.2-2.7c-0.1-5.1-0.4-7.9-0.8-8.3
		c-0.5-0.6-0.5-5.5,0-6.2c0.3-0.4,0.5-0.8,0.5-1s0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.6,0.6-0.8c0-0.1,0.3-0.5,0.7-0.8l0.7-0.6l1.3,0.7
		c0.7,0.4,2,0.8,2.8,0.9c0.9,0.1,2.1,0.5,2.8,0.8c1.6,0.8,2.2,0.9,3,0.6c0.7-0.2,1.5,0.1,2.4,1c0.4,0.4,0.7,0.5,2.2,0.5
		c1.9,0,2.3-0.1,3.2-1c1.2-1.1,3.5-0.6,5.5,1.2c0.6,0.6,1.5,1.3,1.9,1.6c0.4,0.3,1,1.1,1.4,1.7c0.4,0.7,1.1,1.8,1.7,2.5
		c0.5,0.7,1.2,1.8,1.5,2.4c0.4,0.8,0.7,1.2,1.1,1.3c2.4,0.5,4,1.8,4.2,3.4c0.3,2.1,1.4,3.5,3.2,4.1c1.5,0.5,1.8,0.1,1.8-2.5
		c0-1.6,0.7-2.6,2.9-4.4c0.6-0.5,0.6-0.6,0.5-2.1c-0.2-1.9,0.1-2.2,1.9-2.8c1.7-0.5,2.3-0.9,3.5-1.9c1.2-1.1,1.8-1.1,2.2-0.2
		c0.7,1.3,1.7,1.4,3.3,0.2c0.5-0.4,1.3-0.7,1.9-0.8c1.7-0.2,1.6-0.2,1.1-2.8c-0.6-2.7,1-4.5,5-5.6c0.5-0.1,1.6-0.8,2.6-1.5
		c1.8-1.4,2.4-1.5,4.4-1c1.4,0.4,2.4,0.1,4-1.1c2.5-1.8,4.2-2.2,10.6-2.4c1.8,0,2-0.1,2.8-0.8c1.1-0.9,2.2-1.5,3.6-1.8
		c1.6-0.3,7.6-2.4,8.8-3c0.4-0.2,1.2-0.5,1.9-0.6c1.3-0.2,2.7-1.1,4.3-2.6c0.8-0.8,1.3-1.1,2.1-1.2c0.6-0.1,1.5-0.5,1.9-0.8
		c0.5-0.3,1.3-0.7,1.9-0.8c0.7-0.1,1.2-0.4,1.7-1c0.5-0.5,1.1-0.9,1.9-1.1c1.2-0.4,1.8-0.8,4.2-2.8c2-1.7,5.2-2.4,5.2-1.2
		c0,0.7,0.8,1.4,1.5,1.2c1.2-0.3,1.9-1.5,2.2-3.8c0.2-1.6,1.2-2.6,3.3-3.4c2.9-1.1,4.2-2.4,5.4-5.2c0.3-0.8,0.9-1.6,1.5-2.1
		c0.5-0.5,1.3-1.4,1.7-1.9c0.4-0.6,1.8-2.1,3.1-3.3c1.3-1.2,2.8-2.7,3.2-3.4c0.9-1.2,2.5-2.9,4-4.2c0.5-0.4,2.4-2.4,4.3-4.3
		s4.4-4.3,5.7-5.4c1.3-1,2.3-1.9,2.3-2s0.6-0.8,1.2-1.6c0.7-0.8,1.5-2.1,1.9-2.9c0.4-0.9,1.1-1.7,1.6-2.1s1.3-1.4,1.9-2.2
		c0.5-0.8,1.3-1.8,1.7-2.3c1.3-1.4,0.6-2.4-1.1-1.5c-0.7,0.4-2.8,3.1-3.9,5c-0.6,1.1-1,1.5-2.1,2c-0.9,0.5-1.5,1-2,1.8
		c-1.1,1.6-1.7,2.1-2.9,2.8c-0.6,0.3-1.5,0.9-2,1.3c-1,0.9-1.4,1.1-3.1,1.3c-2.3,0.3-4.1,1-5.2,1.9c-0.5,0.5-1.6,1.2-2.3,1.6
		c-0.9,0.5-1.5,1-1.9,1.5c-0.6,0.9-1.3,1.2-2.6,1.2c-1.1,0-1.4,0.2-1.7,1.5c-0.3,1.1-0.8,1.8-2.5,3c-0.7,0.5-1.7,1.2-2.3,1.6
		c-0.7,0.5-1.4,0.8-2.2,0.9c-1.3,0.2-1.5,0.4-1.5,1.4c0,1.9-3.6,4.8-6.6,5.4c-2.9,0.5-4.7,1.1-5.6,1.7c-0.9,0.6-3.7,1.3-5.3,1.3
		c-0.8,0-1.8,0.4-3,1.3c-0.7,0.5-0.9,0.6-3,0.5c-1.2,0-3,0.1-4,0.2c-1.1,0.2-2.6,0.2-3.9,0.2c-1.2-0.1-2.5,0-3,0.1
		c-0.6,0.2-1.1,0.2-1.7,0c-0.7-0.2-1-0.2-1.8,0.1c-1.4,0.5-2.3,0.5-4-0.4c-2.1-1.1-2.5-1.1-3.9-0.6c-0.6,0.2-1.6,0.5-2.2,0.5
		c-0.6,0-1.7,0.2-2.5,0.4c-4.4,1.2-5.4,1.3-6.1,0.7c-0.3-0.2-1-0.7-1.7-1c-1.4-0.8-2.2-1.5-2.9-2.7c-0.6-1-0.8-1.1-2.2-1.5
		c-0.8-0.2-0.9-0.4-1.2-1.3c-1.4-4-4.6-5.6-5.6-2.7c-0.5,1.4-0.6,1.5-2.2,1c-1.5-0.5-2.2-1-2.2-1.8c0-2.2-2.9-2.8-4.8-1l-1.2,1.1
		l-1.3-0.3c-2-0.4-2.6-1.2-1.8-2.3c0.4-0.5-0.2-1.2-1-1.2c-0.2,0-0.9-0.3-1.7-0.6c-2.4-1-2.5-1.1-2.9-1.1c-0.6-0.1-0.7,0.4-0.3,1.2
		c0.5,1,0.4,1.6-0.2,1.9c-1,0.4-1.6-0.1-2.4-1.8c-0.6-1.4-0.8-1.6-1.8-2.1c-1.9-0.9-2.7-2-2.5-3.5s-0.3-2.1-2.5-2.8
		c-0.7-0.2-1.5-0.7-1.8-1c-0.4-0.4-0.8-0.7-1-0.7c-0.7,0-1.3-0.8-1.3-1.9c0-0.8-0.1-1.1-0.6-1.5c-3.7-3.1-3.8-3.2-3.5-5.5
		c0.2-1.6,0.2-1.7,0.9-1.9c1-0.3,1.2-0.8,1.2-2c-0.1-1.2-0.5-1.8-0.9-1.3c-0.6,0.6-2.1,0.3-2.8-0.5c-1.5-1.7-2.4-5.5-1.3-5.5
		c1.6,0,0.3-4.8-1.6-6.1c-2.7-1.8-8.2-9.7-8.2-11.8c0-2.4-0.6-3.1-2.3-2.9c-0.6,0.1-1.4,0-1.8-0.1c-0.9-0.4-1.8-0.1-2.3,0.7
		c-0.5,0.9-0.6,0.9-2.5-1.1c-1.4-1.4-2.7-2.3-3.3-2.3c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0.8-0.5,1.3-0.6,1.6c-0.3,0.8-0.4,1.1-0.3,1.4
		c0.1,0.2,0.4,0.2,0.9,0.3c1.3,0.2,2,2.6,0.8,2.6c-2.9,0.2-3.3,0-3.3-1.3c0-0.9,0-1-0.7-1.1c-1.9-0.3-4.7,1.8-4.7,3.7
		c0,0.4-0.1,0.9-0.2,1.1c-1.2,2.2-1.1,2.5,0.7,2.5c2.3,0,3.7,1.3,3.5,3.2c-0.2,1.3,0.8,2.7,1.9,2.7c0.3,0,0.3,0.4,0.3,1.9
		c0,2.3,0.2,2.7,1.6,3.1c0.8,0.2,1.3,0.6,2.4,1.6c0.7,0.8,1.5,1.4,1.7,1.4c1.1,0,2.3,1.7,1.4,2c-0.7,0.3-1.3,0-3.1-1.7
		c-0.8-0.8-1.3-1.1-2.1-1.2c-0.7-0.1-1.2-0.4-1.9-1c-0.5-0.5-1.1-0.9-1.3-0.9c-0.7,0-1.4-0.7-2.2-2.3c-0.8-1.6-2.6-3.5-3.1-3.5
		s-0.8-0.9-0.7-1.9c0.3-2.2-0.8-2.8-2.2-1.2c-0.4,0.5-0.9,1.2-0.9,1.5c-0.1,0.3-0.2,0.5-0.3,0.5c-0.4,0-0.5,1.4-0.1,1.8
		c0.5,0.6,0.4,1.6-0.2,2.1c-2,1.5-1.9,9.3,0.1,10.6c0.1,0.1,0.5,0.6,0.8,1.2s0.7,1.2,0.9,1.4c0.2,0.2,0.7,1,1,1.8
		c0.4,0.8,1.2,2.2,1.8,3.1c1.9,2.8,2,3.1,1.8,5.6c-0.2,2.4-0.2,4.3,0,7.6c0.1,1.5,0,2.9-0.2,4.4c-0.2,1.4-0.3,3.2-0.3,5.2
		c0,2.5,0,3.4-0.4,4.8c-0.4,1.6-0.5,2-0.2,3.4c0.3,2.2,0.4,3.3,0.1,5.4c-0.1,1-0.4,3.6-0.5,5.7c-0.4,8.1-1.8,10.7-5.2,9.9
		c-1.8-0.4-2.5-0.2-8.1,2.7c-4.5,2.4-5.6,3-10.6,6.2c-2.9,1.8-6.9,3.9-10.2,5.2c-1.8,0.7-3.6,1.7-5.6,3c-3.4,2.2-4.8,3-11.8,6.6
		c-2.8,1.4-6.8,3.5-8.8,4.7c-2.1,1.2-4.4,2.5-5.3,3c-0.8,0.5-3.2,1.8-5.2,3 M109.8,379.8c0,0.1,0.2,0.2,0.3,0.2
		c0.2,0,0.3-0.1,0.3-0.2s-0.2-0.2-0.3-0.2C109.9,379.5,109.8,379.6,109.8,379.8 M93.7,383.2c-1.5,0.6-2.4,1.3-2.4,2
		c0,1,0.9,3.8,1.3,4.4c0.3,0.3,0.8,1.1,1.2,1.6c0.7,1,0.8,1.2,1.5,1.1c1.3-0.1,1.6-0.9,0.9-2.4c-0.4-0.9-0.3-2.3,0.2-2
		c0.1,0.1,0.2,0,0.2-0.1s0.2-0.2,0.5-0.1c0.6,0.2,0.9-0.2,0.9-1c0-0.4,0.1-0.7,0.4-0.8c0.2-0.1,0.4-0.3,0.4-0.4
		c0-0.2,0.2-0.4,0.3-0.4c1-0.4,0.1-1.7-1-1.4c-0.3,0.1-0.7,0.2-0.8,0.1c-0.1,0-0.2,0-0.2,0.1c0,0.5-1.1,0.2-1.7-0.5l-0.7-0.8
		L93.7,383.2 M199,393.7c-0.2,0.3,0.2,0.7,0.7,0.6c0.7-0.1,0.7-0.9,0-0.9C199.4,393.5,199.1,393.6,199,393.7 M187.9,394
		c-1.1,0.8-0.5,1.7,1.5,2.2c3.1,0.9,4.7-0.5,2-1.8C189.9,393.7,188.6,393.6,187.9,394 M136.3,399.9c0,0.2,0,0.3,0.1,0.3
		s0.3,0.1,0.4,0.1c0.2,0.1,0.4,0,0.4-0.2s-0.2-0.4-0.4-0.5C136.5,399.6,136.3,399.7,136.3,399.9 M124.5,408c1.7,1.1,2.1,2.3,0.7,1.9
		c-0.8-0.2-2-1.4-2-2.1C123.3,407.3,123.5,407.3,124.5,408 M129.4,411.6c0.2,0.2,0.2,0.4,0.1,0.5c-0.2,0.2-0.8-0.1-0.8-0.5
		S129,411.2,129.4,411.6 M131.1,413c0.7,0,1,0.6,0.5,1c-0.5,0.5-1.1,0.2-1.1-0.6c0-0.2-0.2-0.5-0.3-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.1-0.3,0.5,0.1C130.5,412.8,130.9,413,131.1,413 M290.3,473.4c-0.3,0.1-0.7,0.2-0.9,0.6c-0.5,1.3,3,3.8,8.2,7.7
		c10.3,7.8,12.2,10.3,13.2,18c0.9,6.6-0.6,11.1-5.8,18c-5.1,6.7-6.5,8.3-9.1,11.2c-2,2.1-4,4.1-6.3,5.8c-1.8,1.5-3.9,3.3-4.6,4
		l-1.3,1.3h45.1c8.7,0,17.4-1.2,25.8-3.4c2.5-0.7,8.6-3.8,11.2-5.5l2-1.5c2.4-2.6,3.1-5,3.2-8.5c0.1-3.4,0-3.9-2.4-6.3
		c-1-0.9-1-0.9-4.6-2.7c-2.5-1.2-3.3-2.1-6.4-3.6c-1.3-0.7-2.5-1.2-3.2-1.5c-1.2-0.4-3-1.4-4-2c-1.1-0.7-3.4-2-5.2-2.9
		c-1.8-0.9-4.6-2.7-6.3-3.8c-1.7-1.2-3.8-2.7-4.7-3.3c-0.9-0.6-2.8-2-4.2-3.1c-1.4-1.1-3.6-2.6-5.1-3.4c-1.4-0.8-4-2.3-5.7-3.4
		s-5-2.9-7.3-4.1s-5-2.7-6.1-3.4c-3.9-2.5-3.9-2.2-4.3-2.4C299.2,474,295.7,472.9,290.3,473.4z"
      />
    </g>
    <path className="st0" d="M42.2,525.9" />
  </Svg>
);
