import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@lundgren/gatsby-theme-lundgren-blog/src/components/Layout.js";
import { Link } from 'gatsby';
import Skills from '../components/Skills.js';
import { H2, Button, WideContainer } from '$components';
import { Flex, Box, Text } from '@rebass/emotion';
import TobiasImg from '../components/tobias-image.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <WideContainer mdxType="WideContainer">
  <Flex flexDirection={['column', 'row']} flexWrap="wrap" mt={[4, 0]} mdxType="Flex">
    <Flex alignSelf="center" width={[1, 1 / 2]} flexDirection="column" mdxType="Flex">
      <H2 mdxType="H2">Hello world! 👋</H2>
      <Text fontSize={['1.25em', '1.5em']} mdxType="Text">
        <Skills items={[`I'm a full Stack developer`, `I'm a computer science student`, `I'm an entrepreneur`, `I'm a software engineer`]} mdxType="Skills" />
        <br />I will share my thoughts and portfolio here.
      </Text>
      <br />
      <Text mdxType="Text">
        Feel free to contact:{' '}
        <a href="mailto:me@lundgren.tech">me@lundgren.tech</a>.
      </Text>
    </Flex>
    <Box width={[1, 1 / 2]} mdxType="Box">
      <TobiasImg mdxType="TobiasImg" />
    </Box>
  </Flex>
    </WideContainer>
    <h2>{`Blog`}</h2>
    <p>{`What’s on my mind and in my notes - a collection of my personal best practises and thoughts.`}</p>
    <hr></hr>
    <h3>{`No blog posts yet 😢`}</h3>
    <p>{`Stay tuned - I will add some blog posts soon...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      